import { useEffect, useState } from 'react';
import { updateBulkLearnerFeature } from '../../../../util/APIUtils';
const useVoiceCallAccessToggleHook = ({
  isToggleOn,
  userData,
  onUpdateResponse
}) => {

  useEffect(() => {
    setToggleState(isToggleOn);
  }, [isToggleOn])
  


  const [toggleState, setToggleState] = useState(isToggleOn);

  const handleToggle = (event) => {
    const { checked } = event.target;
    const putBody = [{
      personId: userData?.learnerId,
      featureName: 'Voice_Calls',
      status: checked
    }];
    setToggleState(checked);
    updateBulkLearnerFeature(putBody).then((response) => {
      if(response?.successList?.length>0){
        onUpdateResponse("Voice call access updated successfully", true)
      }else{
        onUpdateResponse("Failed to update voice call access", false)
        setToggleState(!checked);
      }

      
    }).catch((error) => {
      onUpdateResponse("Failed to update voice call access", false)
      setToggleState(!checked);
    });
    
  };


  return {
    toggleState,
    setToggleState,
    handleToggle,
  };

}

export default useVoiceCallAccessToggleHook;