import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './call-history-details-v2.style';
import { audioPlayerUseStyles } from './call-history-details-v2.style';
import VoiceInsightChip from '../voice-insight-chip/voice-insight-chip';
import { Box, Typography, IconButton, Grid, Button, Drawer } from '@material-ui/core';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';

import AudioPlayer from 'material-ui-audio-player';
import { calculateDurationInMins, formatDatetime, getFullName } from '../../utils/utils';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TranscriptPdf from '../transcript-pdf/transcript-pdf';
import Transcript from '../transcript/transcript';
import moment from 'moment-timezone';
import FlaggedKeywords from '../flagged-keywords/flagged-keywords';
const CallHistoryDetailsDrawer = (props) => {
  const {
    classes,
    isDetailModalOpen,
    handleCloseHistoryDetailsModal,
    learnerFirstName,
    learnerLastName,
    learnerDocId,
    contactPhoneNumber,
    contactInfo,
    joinTimeUtc,
    endTimeUtc,
    recordingUrl,
    transcriptData,
    transcriptSummary,
    detectedKeywords,
    isPrivileged,
  } = props;
  let contactInfoObj = {};
  try {
    contactInfoObj = contactInfo ? JSON.parse(contactInfo) : {};
  } catch (error) {
    contactInfoObj = {};
  }
  const hasTranscript = transcriptData && transcriptData.transcript && transcriptData.transcript.length > 0;
  const hasSummary =
    hasTranscript && transcriptSummary && transcriptSummary.main_topics && transcriptSummary.main_topics.length > 0;

  return (
    <Drawer anchor="right" open={isDetailModalOpen} className={classes.root}>
      <Box className={classes.drawer}>
        <Box className={classes.drawerHeader}>
          <IconButton
            id="close-button"
            aria-label="close"
            className={classes.closeIcon}
            onClick={handleCloseHistoryDetailsModal}
          >
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
        </Box>
        <Box className={classes.drawerContent}>
          <Typography variant="h1">Call Details</Typography>
          <Grid container spacing={1}>
            <Grid xs={12} className={classes.callDetailsSection}>
              <Grid item xs={6}>
                <Box className={classes.infoGroup}>
                  <Typography id="learner-username" variant="h6" className={classes.infoHeading}>
                    {learnerFirstName} {learnerLastName || 'N/A'}
                  </Typography>
                  <Typography className={classes.identity}>Resident</Typography>
                  <Typography y id="learner-doc-id" className={classes.infoDetails}>
                    {learnerDocId || 'N/A'}
                  </Typography>
                </Box>
              </Grid>

              <IconButton>
                <PhoneForwardedIcon className={classes.phoneForwardedIcon} />
              </IconButton>

              <Grid item xs={6}>
                <Box className={classes.infoGroup}>
                  <Typography variant="h6" id="contact-full-name" className={classes.infoHeading}>
                    {getFullName(contactInfoObj.firstName, contactInfoObj.lastName) || 'N/A'}
                  </Typography>
                  <Typography className={classes.identity}>Visitor</Typography>
                  <Typography id="learner-contact-details" className={classes.infoDetails}>
                    {contactPhoneNumber} | {contactInfoObj.relationshipType || 'N/A'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid xs={12} className={classes.recordingSection}>
              <Grid item xs={12}>
                <Box className={classes.recordingDetails}>
                  <Typography variant="h2">Recordings</Typography>
                  <Typography variant="body2" id="call_duration" className={classes.textGray}>
                    <span className={classes.callDurationText}>Call Duration</span>:{' '}
                    {calculateDurationInMins(joinTimeUtc, endTimeUtc)}
                  </Typography>
                  <Typography variant="body2" id="join_time" className={classes.textGray}>
                    {formatDatetime(endTimeUtc)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.audioPlayerSection} id="audio-player">
                  {recordingUrl ? (
                    <AudioPlayer
                      elevation={0}
                      variation="primary"
                      download={true}
                      order="standart"
                      preload="auto"
                      rounded={true}
                      src={recordingUrl}
                      useStyles={audioPlayerUseStyles}
                    />
                  ) : (
                    <Typography className={classes.noRecordingAvailable} id="noRecordingAvailable">
                      <CustomIcons icon={icons.NoRecordingIcon} className={classes.noRecordingAvailableIcon}></CustomIcons>
                      {isPrivileged ? 'Call Recording Unavailable for Privileged calls' : 'Audio Recording Unavailable'}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            
            {detectedKeywords?.length > 0 &&
            <Box className={classes.voiceInsightChip}>
              <Typography variant="body2" id="call_insight" data-testid="call-insight-title" className={classes.callInsightTextTitle}>
                <span>Call Insights</span>:{' '}
              </Typography>
              <Box className={classes.voiceInsightChip}>
                <VoiceInsightChip key="keyword-detected" label="Keyword Detected" data-testid="voice-insight-chip"/>
                <Typography variant="body2" id="call_insight" data-testid="call-insight-text" className={classes.callInsightText}>
                One or more keywords from the facility dictionary were detected during this call.
                </Typography>
              </Box>
            </Box>
            }

            {hasTranscript && (
              <Box className={classes.dialogActions} id="transcript-pdf">
                <FlaggedKeywords keywordsData={detectedKeywords} />
                <Typography variant="h3" className={classes.transcriptTitle}>
                  Transcript
                </Typography>
                <Transcript
                  data={transcriptData.transcript}
                  learnerName={learnerFirstName + ' ' + learnerLastName}
                  contactName={(contactInfoObj.firstName || '') + ' ' + (contactInfoObj.lastName || '')}
                  detectedKeywords={detectedKeywords}
                />

                <PDFDownloadLink
                  document={
                    <TranscriptPdf
                      learnerName={learnerFirstName + ' ' + learnerLastName}
                      callerName={(contactInfoObj.firstName || '') + ' ' + (contactInfoObj.lastName || '')}
                      transcriptData={transcriptData}
                      callDuration={calculateDurationInMins(joinTimeUtc, endTimeUtc)}
                      dateTime={formatDatetime(endTimeUtc)}
                      transcriptSummary={transcriptSummary}
                    />
                  }
                  fileName={`${moment().format('YYYY-MM-DD')}-Transcript-${learnerLastName}-${contactInfoObj.lastName}.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Loading document...'
                    ) : (
                      <Button variant="outlined" id="downloadTranscript" className={classes.downloadTranscriptBtn}>
                        Download Transcript
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              </Box>
            )}

            {hasSummary && (
              <>
                <Grid xs={12} className={classes.transcriptSection}>
                  <Grid item xs={12}>
                    <Typography variant="h3">Summary</Typography>
                    <Box className={classes.summaryBox}>
                      <Typography variant="h6" className={classes.infoHeading}>
                        Main Topics
                      </Typography>
                      <Typography variant="body1" id="transcript-summary" className={classes.summaryText}>
                        {transcriptSummary.main_topics}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default withStyles(styles)(CallHistoryDetailsDrawer);
