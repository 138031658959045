export default theme => ({
  root: {
    position: 'relative',
    zIndex: 999
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 61,
    right: 85,
    maxHeight: 'calc(85vh - 135px)',
    boxShadow: '0px 2px 2px 2px #80808042',
    borderRadius: 30,
    padding: '20px 0px',
    width: 450,
    maxWidth: '100%',
    overflow: 'hidden',
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  scrollable: {
    flex: 1,
    overflow: 'auto'
  },
  footer: {
    textAlign: 'right',
    padding: '0px 20px'
  },
  headTextWrapper: {
    color: theme.palette.primary.navy,
    marginLeft: 10,
    '& *':{
      textTransform: 'lowercase !important'
    }
  },
  headIcon: {
    marginLeft: 3,
    '& svg': {
      fontSize: 16
    }
  },
  bodyTextWrapper: {
    color: 'var(--grey-110)'
  },
  itemHead: {
    display: 'flex',
    alignItems: 'center'
  },
  itemBody: {
    paddingLeft: 20,
    borderLeft: '1px solid var(--grey-40)',
    marginLeft: 10,
    color: 'var(--grey-110)'
  },
  learnerIcon: {
    color: theme.palette.primary.cerulean
  },
  staffIcon: {
    color: theme.palette.primary.mainBlue
  },
  footerText: {
    fontSize: 12
  },
  notFoundWrapper: {
    height: 200
  },
  notFoundScrollable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  alignFooterButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonPrimaryOutlined: {
    color: theme.palette.primary.mainBlue
  },
});
