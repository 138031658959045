import { makeStyles } from '@material-ui/styles';
export default (theme) => ({
  title: {
    fontSize: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.colors.grey50,
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  body: {
    padding: '15px 20px',
  },
  formGroup: {
    display: 'flex',
  },
  infoGroup: {
    flex: 1,
    padding: '16px 24px 16px 24px',
    border: `1px solid ${theme.palette.colors.grey50}`,
    borderRadius: '12px',
    marginRight: '10px',
    maxWidth: '100%',
    marginTop: '10px',
  },
  infoHeading: {
    color: theme.palette.navy,
    fontWeight: 700,
    fontSize: 16,
  },
  identity: {
    color: theme.palette.colors.ceruleanMain,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '3px',
  },

  infoDetails: {},

  modal: {
    maxWidth: 570,
    margin: '0 auto',
    marginTop: '10vh',
  },
  footer: {
    padding: '0px 20px 25px',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leaveBtn: {
    background: '#ff0000',
    borderRadius: 35,
    color: 'white',
    padding: 15,
    fontWeight: 'bold',
  },
  audioPlayerSection: {
    padding: '16px 24px 16px 24px',
    border: `1px solid ${theme.palette.colors.grey50}`,
    borderRadius: '12px',
    marginTop: 14,
    marginBottom: 14,
  },
  textGray: {
    color: theme.palette.colors.grey10,
  },
  callDurationText: {
    fontWeight: 'bold',
  },
  callInsightText : {
    color: theme.palette.colors.grey10,
    marginTop: '12px',
    marginLeft: '16px',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
  },
  voiceInsightChip: {
    marginTop: '12px'
  },
  callInsightTextTitle: {
    fontWeight: '700',
    fontFamily: 'Inter',
    marginTop: '12px',
    fontSize: '24px',
    color: 'var(--navy-main)'
  },
  recordingSection: {
    marginTop: '12px',
  },
  recordingDetails: {
    paddingLeft: 3,
  },

  callDetailsSection: {
    display: 'flex',
  },

  noRecordingAvailable: {
    color: theme.palette.greyScale.darkSilver,
    display: 'flex',
    fontWeight: 700,
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  noRecordingAvailableIcon: {
    marginRight: '5px',
    fontWeight: 700,
    color: theme.palette.greyScale.darkSilver,
  },
  recordingItem: {
    paddingLeft: '14px',
  },

  transcriptSection: {
    marginTop: '12px',
    marginBottom: '10px',
  },
  transcriptPdf: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  transcriptTitle: {
    marginBottom: theme.spacing(2),
  },
  summaryTitle: {
    textTransform: 'uppercase',
  },
  summaryBox: {
    padding: '16px 24px 16px 24px',
    border: `1px solid ${theme.palette.colors.grey50}`,
    borderRadius: '12px',
    marginTop: '12px',
    textAlign: 'justify',
  },
  summaryText: {},
  downloadTranscriptBtn: {
    textTransform: 'capitalize',
    borderRadius: 35,
    color: theme.palette.primary.blue.main,
    border: `1px solid ${theme.palette.primary.blue.main}`,
    fontWeight: 700,
    fontSize: 16,
    marginTop: 8,
  },
  dialogActions: {
    padding: '10px 0',
    justifyContent: 'left',
  },
  root: {
    '& .MuiDrawer-paper': {
      padding: '0px 12px 16px 12px !important',
      width: '768px !important',
      overflow: 'none',
      borderLeft: '10px solid',
      borderImage: `linear-gradient(to bottom left, ${theme.palette.colors.accentTeal} 9.18%, ${theme.palette.primary.deep} 90.82%) 1`,   
  },
  },

  closeIcon: {
    color: theme.palette.primary.main,
  },
  drawerContent: {
    width: '744px',
    padding: '12px 24px 32px 20px',
    gap: '16px',
    opacity: '0px',
    height: 'auto',
  },
  drawerHeader: {
    padding: '16px 0px 0px 0px',
  },
  phoneForwardedIcon: {
    color: theme.palette.colors.grey60,
    marginRight: '5px',
  },
});

export const audioPlayerUseStyles = makeStyles((theme) => {
  return {
    root: {
      '& a': {
        color: `${theme.palette.primary.blue.main} !important`,
      },
    },
    playIcon: {
      color: `${theme.palette.primary.blue.main} !important`,
    },
    replayIcon: {
      color: `${theme.palette.primary.blue.main} !important`,
    },

    pauseIcon: {
      color: `${theme.palette.primary.blue.main} !important`,
    },
    volumeIcon: {
      color: `${theme.palette.primary.blue.main} !important`,
    },
    volumeSlider: {
      color: `${theme.palette.primary.blue.main} !important`,
    },
    progressTime: {
      color: `${theme.palette.primary.blue.main} !important`,
    },
    mainSlider: {
      color: `${theme.palette.primary.blue.main} !important`,
    },
  };
});
