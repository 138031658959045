import { useEffect, useState } from 'react';
import React from 'reactn';
import { columnSortByOrderDirection } from '../../../../util/Helpers';
import { VOICE_CALL } from '../../constants/voice-call-constants';
import { VoiceCallService } from '../../services/voice-call.service';
import { LOCATIONS, LEARNER_STATUS } from '../../../../constants';
import { Link } from 'react-router-dom';
import VoiceCallAccessToggle from '../voice-call-access-toggle/voice-call-access-toggle.component';
import { Typography } from '@material-ui/core';
import CustomMenu from '../../../management/menu';
import useUnleashHook from '../../../../v2/core/unleash/useUnleashHook';
import { UnleashService } from '../../../../v2/services';

const useLearnerManagementComponentHook = (
  activeTab,
  locationId,
  classes,
  isDataRefresh,
  setDataRefresh,
  shouldExportCallHistory,
  setShouldExportCallHistory
) => {
  const voiceCallService = new VoiceCallService();
  const defaultPageSize = 10;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [searchLearnerLoading, setSearchLearnerLoading] = useState(false);
  const [learnerListFetched, setLearnerListFetched] = useState(false);

  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(6000);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [stateFilters] = useState({
    learnerName: null,
    locationName: null,
    scheduleName: null,
  });
  const [allowedFilters, setAllowedFilters] = useState([
    'learnerName',
    'locationName',
    'scheduleName',
  ]);

  const noScheduleData = { id: -1, scheduleName: 'No Schedule (Learners cannot make calls)' };

  const [showScheduleAssignmentModal, setShowScheduleAssignmentModal] = useState(false);
  const [scheduleList, setScheduleList] = useState([noScheduleData]);
  const [selecetedSchedule, setSelectedSchedule] = useState(noScheduleData);
  const [selectedLearner, setSelectedLearner] = useState({});
  const [snackbarConfig, setSnackbarConfig] = useState({ showSnackbar: false, snackbarType: 'success', message: '' });
  const [disableActionButtons, setDisableActionButtons] = useState(false);
  const [assignedScheduleId, setAssignedScheduleId] = useState(null);
  const tableRef = React.useRef();

  const { isFlagEnabled: scheduleAssignmentFlag } = useUnleashHook(UnleashService.FLAGS.VOICE_GROUP_SCHEDULE);

  useEffect(() => {
    if (isDataRefresh) {
      refreshTable();
      setDataRefresh(false);
    }
  }, [isDataRefresh]);
  useEffect(() => {
    if (shouldExportCallHistory && totalCount) {
      handleExportCsv(tableColumns);
      setShouldExportCallHistory(false);
    }
    if (shouldExportCallHistory && !totalCount) {
      handleSnackbar('No data to export', false, 2000);
    }
  }, [shouldExportCallHistory, totalCount]);

  useEffect(() => {
    setShouldExportCallHistory(false);
    setDataRefresh(false);

    voiceCallService
      .getScheduledList({ locationId })
      .then((data) => {
        if (data?.content) {
          const schedules = data.content.reduce((acc, item) => {
            item.schedule.forEach((sch) => {
              acc.push({
                id: sch.id,
                scheduleName: sch.scheduleName,
                isDefault: sch.id === item.defaultScheduleId,
              });
            });
            return acc;
          }, []);

          const sortedSchedules = [...schedules].sort((a, b) => {
            if (a.isDefault && !b.isDefault) return -1;
            if (!a.isDefault && b.isDefault) return 1;
            return 0;
          });

          setScheduleList([noScheduleData, ...sortedSchedules]);
        }
      })
      .catch((error) => {
        console.error('Error fetching schedule list:', error);
      });
  }, [locationId]);

  const handleSnackbar = (message, success) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
    setSnackbarAutoHideDuration(3000);
    setIsSuccessSnackbar(success);
  };

  const showSnackBarAfterContactExport = (message, success, duration) => {
    handleSnackbar(message);
    setSnackbarAutoHideDuration(duration);
    setIsSuccessSnackbar(success);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  let tableColumns = [
    {
      title: 'Resident Name',
      field: 'learnerName',
      defaultSort: 'asc',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 150,
      },
      render: (rowData) => {
        const handleLinkClick = () => {
          localStorage.setItem('selectedLearner', JSON.stringify(rowData));
        };

        return (
          <Link to={`/voiceCalls/learnerProfile`} onClick={handleLinkClick}>
            {rowData.learnerName}
          </Link>
        );
      },
    },
    {
      title: 'Location Name',
      field: 'locationName',
      filtering: true,
      sorting: true,
      cellStyle: {
        minWidth: 100,
      },
    },
    {
      title: 'Access',
      field: 'voiceCallEnabled',
      filtering: true,
      sorting: true,
      render: (rowData) => {
        const isToggleOn = rowData.voiceCallEnabled !== false
        return (
          <VoiceCallAccessToggle
            isToggleOn={isToggleOn}
            userData={rowData}
            onUpdateResponse={handleSnackbar}
          />
        );
      },
      cellStyle: {
        minWidth: 100,
      },
    },
  ];

  if (scheduleAssignmentFlag()) {
    tableColumns.push(
      {
        title: 'Schedule',
        field: 'scheduleName',
        filtering: true,
        sorting: true,
        cellStyle: {
          minWidth: 200,
        },
        render: (rowData) => {
          return (
            <>
              {rowData.scheduleName ? (
                <Typography className={classes.scheduleColumnStyle} variant="body2" color="textSecondary" component="p">
                  {rowData.scheduleName}
                </Typography>
              ) : (
                'No schedule assigned'
              )}
            </>
          );
        },
      },

      {
        sorting: false,
        filtering: false,
        render: (rowData) => {
          const options = [
            {
              title: 'Change schedule',
              id: 'changeSchedule',
              onClick: () => {
                handleEditScheduleAction(rowData);
              },
              disabled: rowData.learnerStatus === LEARNER_STATUS.INACTIVE,
            },
          ];
          return <CustomMenu options={options} />;
        },
        cellStyle: {
          textAlign: 'center',
        },
      }
    );
  }

  const setTableFilters = (query) => {
    let filters = { ...stateFilters };
    for (let i = 0; i < query.filters.length; i++) {
      let item = query.filters[i];
      if (allowedFilters.includes(item.column.field)) {
        let value = item.value;
        if (Array.isArray(value)) value = [...item.value];
        filters[item.column.field] = value;
      }
    }
    return filters;
  };
  const createRequestPayload = (query) => {
    const selectedFilters = setTableFilters(query);
    columnSortByOrderDirection(tableColumns, query.orderBy, query.orderDirection);
    const tbody = {
      learnerName: selectedFilters.learnerName,
      locationName: selectedFilters.locationName,
      scheduleName: selectedFilters.scheduleName,
    };
    return { tbody };
  };

  const getLearnersList = (query) => {
    if (!locationId) return;

    const { orderBy, orderDirection } = query;
    const { tbody } = createRequestPayload(query);
    return new Promise((resolve, reject) => {
      voiceCallService
        .getLearnersList({
          locationId,
          pageNo: query.page,
          pageSize: query.pageSize,
          tbody,
          sortBy: orderBy ? orderBy.field : '',
          sortOrder: orderDirection ? orderDirection : '',
        })
        .then((data) => {
          const { content, pageable, totalElements } = data;
          if (content) {
            resolve({
              data: content,
              page: pageable.pageNumber,
              totalCount: totalElements,
            });
            setTotalCount(totalElements);
          } else reject(new Error('Something went wrong'));
        });
    });
  };

  const refreshTable = () => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  };

  const shouldShowExportButton = () => {
    return Boolean(activeTab === VOICE_CALL.TABS.RESIDENT_MANAGEMENT);
  };

  const fetchAllLearnersForExport = async () => {
    try {
      if (tableRef && tableRef.current && tableRef.current.state) {
        const { query } = tableRef.current.state;
        const { orderBy, orderDirection } = query;
        const { tbody } = createRequestPayload(query);
        setLoading(true);
        const allContacts = await voiceCallService.getLearnersList({
          locationId,
          pageNo: 0,
          tbody,
          pageSize: totalCount,
          sortBy: orderBy ? orderBy.field : '',
          sortOrder: orderDirection ? orderDirection : '',
        });
        if (allContacts) {
          return allContacts['content'];
        }
      }
    } catch (error) {
      console.error('Error fetching learners:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportCsv = async (tableColumns) => {
    if (totalCount === 0) {
      showSnackBarAfterContactExport('No contacts found to be exported', false, 2000);
      return;
    }
    const allContacts = await fetchAllLearnersForExport();
    if (!allContacts) {
      showSnackBarAfterContactExport('No contacts found to be exported', false, 2000);
      return;
    }

    const csvContent = generateCsvContent(tableColumns, allContacts);
    if (csvContent) {
      generateCsv(csvContent);
      handleSnackbar('CSV file has been exported successfully', true, 2000);
    }
  };

  const generateCsvContent = (tableColumns, content) => {
    const exemptedColumnsList = ['Actions'];
    const columnTitles = tableColumns
      .filter((column) => !exemptedColumnsList.includes(column.title))
      .map((column) => column.title)
      .join(',');

    let csvContent = columnTitles + '\r\n';
    const domParser = new DOMParser();

    content.forEach((item) => {
      const rowString = tableColumns
        .map((column) => {
          if (column.field) {
            let value = getColumnValue(item, column);
            const document = domParser.parseFromString(value, 'text/html');
            return '"' + document.body.innerText + '"';
          }
        })
        .join(',');
      csvContent += rowString + '\r\n';
    });

    return csvContent;
  };

  const getColumnValue = (item, column) => {
    switch (column.title) {
      case 'Learner Name':
        return item.learnerName || '';
      case 'Location Name':
        return item.locationName || '';
      case 'Schedule':
        return item.scheduleName || '';
      default:
        return item[column.field] || '';
    }
  };

  const generateCsv = (csvContent) => {
    let locationName = getLocationName(locationId);
    const exportedFilename = `${locationName} Learner Managment.csv`;
    const universalBOM = '\uFEFF';
    const downloadLink = document.createElement('a');
    downloadLink.download = exportedFilename;
    downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
    downloadLink.click();
  };

  /**
   * @name getLocationName
   * @param {number} locationId
   * @desc Based upon locationId it finds in LOCATIONS collection
   * for matching location and shows locationName instead.
   * @return {string}
   */
  const getLocationName = (locationId) => {
    const location = LOCATIONS.find((location) => location.locationId === locationId);
    if (location) {
      return location.locationName;
    } else {
      return '';
    }
  };

  const handleEditScheduleAction = (rowData) => {
    const assignedSchedule = rowData.scheduleId
      ? { id: rowData.scheduleId, scheduleName: rowData.scheduleName }
      : noScheduleData;
    setSelectedSchedule(assignedSchedule);
    setAssignedScheduleId(assignedSchedule.id);
    setShowScheduleAssignmentModal(true);
    setSelectedLearner({ learnerId: rowData.learnerId, learnerName: rowData.learnerName });
    setDisableActionButtons(true);
  };

  const handleScheduleChange = (event, newValue) => {
    setDisableActionButtons(assignedScheduleId === newValue.id);
    setSelectedSchedule(newValue);
  };

  const submitScheduleChange = async () => {
    let statusMessage = '';
    let type = 'success';
    setDisableActionButtons(true);

    try {
      if (selecetedSchedule.id === -1) {
        await voiceCallService.unassignSchedule({ personKeys: [selectedLearner.learnerId] });

        statusMessage += `${selectedLearner.learnerName} was assigned to ${selecetedSchedule.scheduleName} \n`;
      } else {
        await voiceCallService.assignSchedule({ scheduleId: selecetedSchedule.id, personKeys: [selectedLearner.learnerId] });

        statusMessage += `${selectedLearner.learnerName} was assigned to ${selecetedSchedule.scheduleName} \n`;
      }
      setDataRefresh(true);
    } catch (error) {
      statusMessage += `Unable to assign ${selectedLearner.learnerName} to ${selecetedSchedule.scheduleName} \n`;

      type = 'error';
    } finally {
      setShowScheduleAssignmentModal(false);
      setDisableActionButtons(false);
      setSnackbarConfig({ showSnackbar: true, snackbarType: type, message: statusMessage });
    }
  };

  return {
    isLoading,
    tableColumns,
    pageSize,
    searchLearnerLoading,
    learnerListFetched,
    openSnackbar,
    handleCloseSnackbar,
    snackbarMessage,
    snackbarAutoHideDuration,
    isSuccessSnackbar,
    fetchAllLearnersForExport,
    getLearnersList,
    tableRef,
    handleExportCsv,
    shouldShowExportButton,
    getLocationName,
    handleSnackbar,
    getColumnValue,
    generateCsvContent,
    showSnackBarAfterContactExport,
    generateCsv,
    showScheduleAssignmentModal,
    setShowScheduleAssignmentModal,
    scheduleList,
    selecetedSchedule,
    setSelectedSchedule,
    selectedLearner,
    submitScheduleChange,
    snackbarConfig,
    setSnackbarConfig,
    disableActionButtons,
    handleScheduleChange,
    handleEditScheduleAction,
    assignedScheduleId,
    setSelectedLearner,
  };
};

export default useLearnerManagementComponentHook;
