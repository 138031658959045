import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, Switch } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { CustomIcons, icons } from '../../../../../../v2/shared/components/custom-icons/CustomIcons';
import { columnSortByOrderDirection } from '../../../../../../util/Helpers';
import { VoiceCallService } from '../../../../services/voice-call.service';
import moment from 'moment';
import { useSelector } from 'react-redux';

const useKeywordsDictionaryHook = ({ classes, locationId }) => {
  const dictionaryIdRef = useRef(0);
  const [dictionaryId, setDictionaryId] = useState(0);
  const selectedKeywordRef = useRef({});
  const {voiceBaseKeywords} = useSelector((state) => state.app);
  const modalRef = useRef(null);
  const [selectedKeywordData, setSelectedKeywordData] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const tableRef = React.useRef();
  const removeKeywordModalRef = useRef(null);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isFirstRender = useRef(true);
  const voiceCallService = new VoiceCallService();


  
  const refreshTable = useCallback(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  },[]);
  
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!locationId) return;
    setSelectedKeywordData(null);
    setIsSearchButtonClicked(false);
    setSearchKeyword('');
    refreshTable();
  }, [locationId, refreshTable]);


  useEffect(() => {
    if (searchKeyword === '' && isSearchButtonClicked) {
      refreshTable();
    }
  }, [searchKeyword, isSearchButtonClicked, refreshTable]);


  const tableColumns = [
    {
      title: 'Keywords (###)',
      field: 'keyword',
      sorting: true,
      cellStyle: {
        fontWeight: 'bold',
        color: '#0078A2',
      },
      render: (rowData) => {
        return (
          <Link
            onClick={() => {
              handleKeywordClick(rowData);
            }}
            className={classes.keywordDetailLink}
            component="button"
          >
            {rowData.keyword}
          </Link>
        );
      },
    },
    {
      title: 'Date added',
      field: 'createdDate',
      sorting: true,
      defaultSort: 'asc',
      render: (rowData) => {
        return moment(rowData.createdDate).format('MM/DD/YYYY');
      },
    },
    {
      title: 'Added by',
      field: 'addedBy',
      sorting: true,
      render: (rowData) => {
        const isOrijin = rowData.addedBy === 'Orijin Dictionary';
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CustomIcons icon={isOrijin ? icons.OrijinKeywords : icons.GenericStaffIcon} />
            <span style={{ marginLeft: 8, marginTop: 8 }}>{rowData.addedBy}</span>
          </div>
        );
      },
    },
    {
      title: 'Monitored',
      field: 'active',
      sorting: false,
      render: (rowData) => {
        return (
          <Switch
            checked={rowData.active === 'true'}
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: rowData.active === 'true' ? classes.trackOn : classes.trackOff,
              checked: classes.checked,
            }}
            icon={<CancelRoundedIcon classes={classes.thumbIcon} />}
            checkedIcon={<CheckCircleIcon classes={classes.thumbIcon} />}
            onChange={(event) => onChangeMonitored(event, rowData)}
          />
        );
      },
    },
  ];

  const getKeywordsList = (query) => {
    if (!locationId) return;
    const { orderBy, orderDirection, pageSize, page } = query;
    columnSortByOrderDirection(tableColumns, orderBy, orderDirection);
  
    return new Promise((resolve, reject) => {
      voiceCallService
        .getKeywordsListByLocation({
          searchKeyword,
          locationId,
          sortBy: orderBy ? orderBy.field : '',
          sortOrder: orderDirection ? orderDirection : '',
          page: page,
          pageSize: pageSize,
        })
        .then((data) => {
          const { content, pageable, totalElements } = data;
          if (content && content.length > 0) {
            const id = content[0].dictionaryId;
            dictionaryIdRef.current = id;
            setDictionaryId(id);
            resolve({
              data: content,
              page: pageable.pageNumber,
              totalCount: totalElements,
            });
          } else if(!isSearchButtonClicked){
            setDictionaryId(0);
            resolve({
              data: prepareBaseKeywordsToDisplay(voiceBaseKeywords),
              page: 0,
              totalCount: voiceBaseKeywords.length,
            });
            createKeywordDictionary();
          }
          else{
            resolve({
              data: [],
              page: 0,
              totalCount: 0,
            });
          }

        })
        .catch((err) => {
          reject(err);
          console.error('Error fetching keywords list:', err);
        });
    });
  };

  const createKeywordDictionary = useCallback(async () => {
    try {
      const response = await voiceCallService.createKeywordDictionary(
        prepareBaseKeywordsToPost(locationId, voiceBaseKeywords, 'Orijin Dictionary')
      );
      if (response) {
        setDictionaryId(response.keywordDictionaryId);
        dictionaryIdRef.current = response.keywordDictionaryId;
        refreshTable();
      }
    } catch (error) {
      console.error('Error creating keyword dictionary:', error);
      handleSnackbarOpen('Failed to create keyword dictionary', 'error');
    }
  }, [locationId, voiceBaseKeywords, voiceCallService]);

  const prepareBaseKeywordsToPost = (locationId,voiceBaseKeywords,createdBy) => {
    const body = {
      locationKey: locationId,
      add: voiceBaseKeywords.map(({ word }) => ({
        keyword: word,
        active: true
      })),
      createdBy: createdBy
    };
  
    return body;
  };
  
  const prepareBaseKeywordsToDisplay = (voiceBaseKeywords) => {
    return voiceBaseKeywords.map((keyword) => {
      return {
        keyword: keyword.word,
        createdDate: new Date(),
        addedBy: 'Orijin Dictionary',
        active: 'true',
      };
    });
  }

  const closeKeywordDetail = () => {
    setSelectedKeywordData(null);
  };

  const handleKeywordClick = (keywordsData) => {
    setSelectedKeywordData(keywordsData);
  };


  const handleAddKeywordDictionaryModal = () => {
    modalRef.current.toggleModal();
    setIsModalOpen(!isModalOpen);
  };

  const handleHideKeywordsModal = useCallback(() => {
    removeKeywordModalRef.current.toggleModal();
  }, []);


  const disableKeyword = () => {
    showOrHideKeyword();
    handleHideKeywordsModal();
  };
  const handleSnackbarOpen = (message, type) => {
    setShowSnackBar(true);
    setSnackbarMessage(message);
    setSnackbarType(type);
  };

  const handleSnackbarClose = () => {
    setShowSnackBar(false);
  };
  
  const showOrHideKeyword = useCallback(async () => {
    try {
      const keywordsPayload = {
        add: selectedKeywordRef.current.active === 'false' ? [selectedKeywordRef.current.keyword] : [],
        remove: selectedKeywordRef.current.active === 'true' ? [selectedKeywordRef.current.keyword] : [],
      };
      await voiceCallService.addKeywordsToDictionary(keywordsPayload, dictionaryIdRef.current);
      refreshTable();
      handleSnackbarOpen('Keyword updated successfully', 'success');
    } catch (error) {
      console.error('Error updating keyword dictionary:', error);
      handleSnackbarOpen('Failed to update keyword', 'error');
    }
  }, [refreshTable, voiceCallService]);

  const onChangeMonitored = useCallback((event, rowData) => {
    selectedKeywordRef.current = rowData;
    event.target.checked ? showOrHideKeyword() : handleHideKeywordsModal();
  }, [showOrHideKeyword, handleHideKeywordsModal]);


  const onClickSearchKeyword = useCallback(() => {
    refreshTable();
    setIsSearchButtonClicked(true);
  }, [refreshTable]);

  const handleSearchKeyword = (event) => {
    const { value } = event.target;
    setSearchKeyword(value);
  };

  return {
    tableRef,
    tableColumns,
    refreshTable,
    getKeywordsList,
    modalRef,
    isModalOpen,
    handleAddKeywordDictionaryModal,
    showSnackBar,
    snackbarMessage,
    snackbarType,
    handleSnackbarOpen,
    handleSnackbarClose,
    selectedKeywordData,
    closeKeywordDetail,
    dictionaryId,
    removeKeywordModalRef,
    disableKeyword,
    handleHideKeywordsModal,
    handleSearchKeyword,
    onClickSearchKeyword,
    searchKeyword,
    setSearchKeyword,
    onChangeMonitored,
    showOrHideKeyword,
  };
};

export default useKeywordsDictionaryHook;
